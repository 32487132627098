import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Referential } from '@core/models/referential.model';
import { environment } from '@env/environment';
import { Equipment } from '@shared/gui/models/equipment.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  private baseUrl: string = environment.apiUrl + '/equipment';

  constructor(private http: HttpClient) {}

  public getEquipments(): Observable<Array<Equipment>> {
    return this.http.get<Array<Equipment>>(this.baseUrl);
  }

  public updateReferentialAndEquipment(
    equipment: Equipment,
    referential?: Referential
  ): Observable<{ equipment: Equipment; referential?: Referential }> {
    return this.http
      .post<{ equipment: Equipment; referential?: Referential }>(`${this.baseUrl}/referential`, {
        equipment,
        referential
      })
      .pipe(
        map(equipmentAndReferential => ({
          equipment: equipmentAndReferential.equipment,
          referential: equipmentAndReferential.referential
        }))
      );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PistonType } from '@core/models/piston-type.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PistonTypeService {
  private baseUrl: string = environment.apiUrl + '/pistonType';

  constructor(private http: HttpClient) {}

  public getPistonTypes(): Observable<Array<PistonType>> {
    return this.http.get<Array<PistonType>>(this.baseUrl);
  }

  public savePistonType(pistonType: PistonType): Observable<PistonType> {
    return this.http.post<PistonType>(this.baseUrl, pistonType);
  }

  public removePistonTypeById(id: string): Observable<void> {
    return this.http.delete<void>(this.baseUrl + '/' + id);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GasBlockBound } from '@core/models/gas-block-bound.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GasBlockBoundService {
  private baseUrl: string = environment.apiUrl + '/gasblockbound';

  constructor(private http: HttpClient) {}

  public getGasBlockBounds(): Observable<Array<GasBlockBound>> {
    return this.http.get<Array<GasBlockBound>>(this.baseUrl);
  }

  public updateGasBlockBound(gasblockbounds: Array<GasBlockBound>): Observable<Array<GasBlockBound>> {
    return this.http.post<Array<GasBlockBound>>(this.baseUrl, gasblockbounds);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PistonTrack } from '@core/models/piston-track.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PistonTrackService {
  private baseUrl: string = environment.apiUrl + '/pistonTrack';

  constructor(private http: HttpClient) {}

  public getPistonTracks(): Observable<Array<PistonTrack>> {
    return this.http.get<Array<PistonTrack>>(this.baseUrl);
  }

  public savePistonTrack(pistonTrack: PistonTrack): Observable<PistonTrack> {
    return this.http.post<PistonTrack>(this.baseUrl, pistonTrack);
  }

  public removePistonTrackById(id: string): Observable<void> {
    return this.http.delete<void>(this.baseUrl + '/' + id);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ColorationContext } from '@core/models/coloration-context.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ColorationContextService {
  private baseUrl: string = environment.apiUrl + '/colorationcontext';

  constructor(private http: HttpClient) {}

  public getColorationContext(): Observable<Array<ColorationContext>> {
    return this.http.get<Array<ColorationContext>>(this.baseUrl);
  }

  public removeColorationContext(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  public upsertColorationContext(colorationContext: ColorationContext): Observable<ColorationContext> {
    return this.http.post<ColorationContext>(this.baseUrl, colorationContext);
  }
}

import { PistonTrack } from '@core/models/piston-track.model';
import { Topology } from '@core/models/topology.model';
import { createReducer, on } from '@ngrx/store';
import { PartData } from '@shared/gui/models/part-data.model';
import { TopologyError } from '@shared/gui/models/topology-error.model';

import * as SettingsActions from './settings.actions';

export const settingsFeatureKey = 'settings';

export interface State {
  // Piston track
  pistonTrackPanelStep: number;
  pistonTrack: PistonTrack;
  pistonTrackErrors: Array<TopologyError>;
  pistonTrackExtremities: Array<PartData>;
  topology: Topology;
}

const initialState: State = {
  pistonTrackPanelStep: 1,
  pistonTrack: null,
  pistonTrackErrors: [],
  pistonTrackExtremities: [],
  topology: null
};

export const reducer = createReducer(
  initialState,
  on(SettingsActions.loadMainTopologySuccess, (state, { topology }) => ({
    ...state,
    topology
  })),
  on(SettingsActions.setPistonTrackStep, (state, { step }) => ({
    ...state,
    pistonTrackPanelStep: step
  })),
  on(SettingsActions.setPistonTrack, (state, { pistonTrack }) => ({
    ...state,
    pistonTrack
  })),
  on(SettingsActions.selectParts, (state, { parts }) => ({
    ...state,
    pistonTrack: {
      ...state.pistonTrack,
      parts: parts ? parts.map(p => ({ key: p.key, type: p.type, equipmentId: p.equipmentId } as PartData)) : []
    }
  })),
  on(SettingsActions.goToItem, (state, { item }) => ({
    ...state,
    pistonTrackErrors: state.pistonTrackErrors.map(i => ({ ...i, selected: i === item }))
  })),
  on(SettingsActions.updatePistonTrack, (state, { errors, length, extremities }) => ({
    ...state,
    pistonTrack: { ...state.pistonTrack, length },
    pistonTrackErrors: errors,
    pistonTrackExtremities: extremities
  })),
  on(SettingsActions.cleanPistonTrackStore, state => ({
    ...state,
    pistonTrackPanelStep: 1,
    pistonTrack: null,
    pistonTrackErrors: [],
    pistonTrackExtremities: [],
    topology: null
  }))
);
